import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class OfferService extends Service {
  fields
  cache

  constructor (httpClient, middleware, cache) {
    super('/products/offer', httpClient, middleware)
    this.cache = cache
    this.fields = [
      'id',
      'barcodes',
      'created',
      'updated',
      'eav',
      'dimensions',
      'extId',
      'image',
      'items',
      'name',
      'price',
      'purchasingPrice',
      'sku',
      'type',
      'state',
      'volume',
      'weight',
      'article',
      'comment'
    ]
  }

  /**
   * @param {object} query
   * @param {String} accept
   *
   * @returns {Promise<object>}
   */
  getAll (query, accept) {
    const updatedQuery = {
      ...query
    }

    if (!updatedQuery.fields) {
      updatedQuery.fields = this.fields
    }

    const data = OfferService.cache.getValues(OfferService.entities.Orderadmin_Products_Entity_Product_Offer, this.getURL(updatedQuery))

    if (!data.isExpired) {
      return Promise.resolve(data.value)
    }

    if (updatedQuery.filter) {
      const check = updatedQuery.filter.some(e => e.field === 'state')
      if (check) {
        updatedQuery.filter.forEach(e => {
          if (e.field === 'state' && e.value === 'bug') {
            e.type = 'neq'
          }
          if (e.field === 'state' && e.value === 'deleted') {
            e.type = 'neq'
          }

        })
      } else {
        updatedQuery.filter.push({ type: 'neq', field: 'state', value: 'bug' })
        updatedQuery.filter.push({ type: 'neq', field: 'state', value: 'archived' })
        updatedQuery.filter.push({ type: 'neq', field: 'state', value: 'deleted' })
      }
    } else {
      if(!query.allStatuses) {
        updatedQuery.filter = [
          { type: 'neq', field: 'state', value: 'bug' },
          { type: 'neq', field: 'state', value: 'deleted' }
        ]
      } else {
        updatedQuery.filter = [
          { type: 'neq', field: 'state', value: 'bug' },
          { type: 'neq', field: 'state', value: 'deleted' }
        ]
      }
    }
    if (updatedQuery.allStatuses) {
      delete updatedQuery.allStatuses
    }

    return super.getAll(updatedQuery, accept)
      .then(data => {
        return {
          ...data,
          items: data.items.map(item => {
            return {
              ...item,
              dimensions: item.dimensions || {}
            }
          })
        }
      })
      .then(data => {
        OfferService.cache.dispatchValues(OfferService.entities.Orderadmin_Products_Entity_Product_Offer, this.getURL(updatedQuery), data)
        return data
      })
  }

  /**
   * @param {string | number | undefined} shop
   *
   * @returns {string}
   */
  _getUrl (shop) {
    if (!shop) {
      return this._url
    }

    return `${this._url}/${shop}`
  }

  /**
   * @param {String | Number} id
   * @param {String | Number} shop
   * @param {object} get
   * @param {String} accept
   *
   * @returns {Promise<object>}
   */
  get (id, shop, get, accept) {
    const value = OfferService.cache.getValue(OfferService.entities.Orderadmin_Products_Entity_Product_Offer, id)

    if (value && value.type !== 'bundle' && value.type !== 'grouped') {
      return Promise.resolve(value)
    }

    return this._httpClient.get(`${this._getUrl(shop)}/${id}`, get, accept)
      .then(item => {
        return {
          ...item,
          dimensions: item.dimensions || {}
        }
      })
      .then(item => {
        OfferService.cache.dispatchValue(OfferService.entities.Orderadmin_Products_Entity_Product_Offer, item)
        return item
      })
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {String | Number} shop
   * @param {object | undefined} headers
   * @param {object | undefined} query
   *
   * @returns {Promise<object>}
   */
  save (data, id, shop, headers, query = {}) {
    return this._httpClient.saveRequest(this._getUrl(shop), data, id, undefined, undefined, headers, query)
      .then(item => {
        return {
          ...item,
          dimensions: item.dimensions || {}
        }
      })
      .then(item => {
        OfferService.cache.dispatchValue(OfferService.entities.Orderadmin_Products_Entity_Product_Offer, item)
        return item
      })
  }

  /**
   * @param {object} entity
   * @param {String | Number} template
   *
   * @returns {Promise<String>}
   */
  getTemplate (entity, template) {
    return this._httpClient
      .getRaw(
        `${this._getUrl(entity._embedded.shop.id)}/${entity.id}`,
        {},
        `application/${template}+document`
      )
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return Promise.reject(new Error(response.statusText))
        }

        return response.text().then((data) => {
          if (data.match('<div')) {
            data = `<html lang='en-us' dir='ltr'><head><meta charset='utf-8'></head><body>${data}</body></html>`
          }

          return data
        })
      })
  }

  /**
   * @param {number|string} shop
   * @param {number/string} id
   *
   * @returns
   */
  print (shop, id) {
    const query = {
      print_case: 'printOfferBarcode',
      filter: [
        { field: 'shop', type: 'eq', value: shop },
        { field: 'id', type: 'eq', value: id }
      ]
    }

    return this._httpClient.get(this._url, query, 'application/pdf')
  }

  upload (data, id = '', shop = '', field) {
    console.log(data)
    if (id && shop) {
      return this._httpClient.uploadPatch(`${this._url}/${shop}/${id}`, data, undefined, field)
    }
    return this._httpClient.upload(`${this._url}`, data)
  }

  downloadAll (query) {
    const updatedQuery = {
      page: 1,
      per_page: 25,
      sort: {
        id: 'desc'
      },
      'order-by': [
        { type: 'field', field: 'id', direction: 'desc' }
      ],
      ...query
    }

    return this._httpClient.download(this._url, updatedQuery, 'application/xls')
  }
}

export const offersService = new OfferService(HALApiService, createItemsData)
